const DETranslation = {
  de: {
    translation: {
      popUp: {
        title: "Warnung für Chrome auf dem iPhone!",
        text: "1. Richten Sie Ihre Kamera auf einen Tisch oder eine helle Oberfläche.",
        text2:
          "2. Bewegen Sie Ihr Handy langsam im Kreis, bis die Zeichnung der Hand verschwindet.",
        text3:
          "3. Verwenden Sie einen Finger, um die Schüssel zu bewegen, und zwei, um sie zu drehen. Wenn Sie die Kamera näher an die Schüssel bringen, sehen Sie sie detaillierter!",
      },
      buttons: {
        accept: "akzeptieren",
      },
      sidebar: {
        languages: {
          title: "Sprachen",
          es: "Spanisch",
          en: "Englisch",
          pt: "Portugiesisch",
          it: "Italienisch",
          zh: "Chinesisch",
          ar: "Arabisch",
          fr: "Französisch",
          he: "hebräisch",
          ru: "Russisch",
          ja: "Japanisch",
          de: "Deutsch",
        },
      },
      index: {
        allCategories: "Alles sehen",
        searchPlaceholder: "Was wollen Sie bestellen?",
        filter: "Filter",
        viewMenu3D: "SIEHE 3D-MENÜ",
        moreInformation: "Weitere Informationen",
        snackbar:
          "Streichen Sie mit einem Finger von links nach rechts über ein Gericht, um es in die Sprache des Kellners zu übersetzen.",
      },
      category: {
        product: {
          dish3d: "3D-Platte",
          outstanding: "Hervorragend",
          extras: "ZUSÄTZE",
          dish360: "360-Gericht"
        },
      },
      product: {
        see360: "IN 360 SEHEN",
        see3D: "IN 3D SEHEN",
        ar: "ERWEITERTE REALITÄT (AR)",
        dietTypes: "Diät-Typen",
        waiterButton: "GERICHT FÜR KELLNER ÜBERSETZEN",
      },
      product3D: {
        viewAR: "SEHEN SIE IN AUGMENTED REALITY (AR)",
        view3D: "IN 3D SEHEN",
      },
      filter: {
        title: "Filter",
        dietTypes: "Diät-Typen",
        dishes3D: "3D / 360 Gerichte",
        clear: "Löschen",
        apply: "Anwenden",
        featuredDishes: "Ausgewählte Gerichte",
        withDiscount: "Mit Rabatt",
      },
      error: {
        title: "Oh!",
        subtitle: "Etwas ist schief gelaufen",
        caption:
          "Stellen Sie sicher, dass der QR oder der Link zu einer Einrichtung gehört, die mit Cartear verbunden ist, oder versuchen Sie es erneut.",
      },
      about: {
        daysAndSchedules: "Tage und Zeiten",
        location: "Standort",
        coverCharge: "Gedeckgebühr",
        socialNetworks: "Soziale Netzwerke",
      },
      days: {
        0: "Montag",
        1: "Dienstag",
        2: "Mittwoch",
        3: "Donnerstag",
        4: "Freitag",
        5: "Samstag",
        6: "Sonntag",
      },
    },
  },
};

export default DETranslation;
