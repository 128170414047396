const RUTranslation = {
  ru: {
    translation: {
      popUp: {
        title: "Помогите использовать дополненную реальность",
        text: "1. Наведите камеру на стол или светлую поверхность.",
        text2:
          "2. Медленно перемещайте мобильный телефон по кругу, пока рисунок руки не исчезнет.",
        text3:
          "3. Используйте один палец, чтобы переместить блюдо и два, чтобы повернуть его. Если вы приблизите камеру к тарелке, то увидите ее более детально!",
      },
      buttons: {
        accept: "ацептар",
      },
      sidebar: {
        languages: {
          title: "Языки",
          es: "испанский",
          en: "Английский",
          pt: "португальский",
          it: "итальянский",
          zh: "Китайский",
          ar: "арабский",
          fr: "Французский",
          he: "иврит",
          ru: "Русский",
          ja: "японский язык",
          de: "Немецкий",
        },
      },
      index: {
        allCategories: "Увидеть все",
        searchPlaceholder: "Что вы хотите заказать?",
        filter: "Фильтр",
        viewMenu3D: "ПОСМОТРЕТЬ 3D МЕНЮ",
        moreInformation: "Информация",
        snackbar:
          "Проведите пальцем слева направо по тарелке, чтобы перевести ее на язык официанта.",
      },
      category: {
        product: {
          dish3d: "3д тарелка",
          outstanding: "Выдающийся",
          extras: "ДОП.",
          dish360: "блюдо 360",
        },
      },
      product: {
        see360: "СМОТРЕТЬ В 360",
        see3D: "ПОСМОТРЕТЬ В 3D",
        ar: "ДОПОЛНЕННАЯ РЕАЛЬНОСТЬ (AR)",
        dietTypes: "Типы диеты",
        waiterButton: "ПЕРЕВЕСТИ БЛЮДО ДЛЯ ОФИЦИАНТА",
      },
      product3D: {
        viewAR: "ДОПОЛНЕННАЯ РЕАЛЬНОСТЬ (AR)",
        view3D: "ПОСМОТРЕТЬ В 3D",
      },
      filter: {
        title: "Фильтр",
        dietTypes: "Типы диеты",
        dishes3D: "3D / 360 блюда",
        clear: "Стереть",
        apply: "Применять",
        featuredDishes: "Рекомендуемые блюда",
        withDiscount: "Со скидкой",
      },
      error: {
        title: "Ой!",
        subtitle: "Что-то пошло не так",
        caption:
          "Убедитесь, что QR-код или ссылка принадлежат заведению, связанному с Cartear, или повторите попытку.",
      },
      about: {
        daysAndSchedules: "Дни и Расписания",
        location: "Местоположение",
        coverCharge: "Плата за место",
        socialNetworks: "Социальные сети",
      },
      days: {
        0: "Понедельник",
        1: "Вторник",
        2: "Среда",
        3: "Четверг",
        4: "Пятница",
        5: "Суббота",
        6: "Воскресенье",
      },
    },
  },
};

export default RUTranslation;
