import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import Header from "../components/product/Header";
import Description from "../components/product/Description";
import Parents from "../components/product/Parents";
import Comments from "../components/product/Comments";
import Actions from "../components/product/Actions";
import vegan from "../images/vegan.svg";
import { setLoading, setRefresh } from "../reducers/loading";
import NotFound from "../pages/NotFound";

const useStyles = makeStyles(() => ({
  ul: {
    zIndex: 10,
    overflowX: "auto",
    paddingBottom: "50px",
    padding: "5px !important",
    background: "white",
    width: "98vw",
  },
  title: {
    fontWeight: "bold",
  },
  image: {
    width: "5vw",
  },
  button: {
    bottom: 0,
    position: "fixed",
    right: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "20px",
  },
}));

const Show = () => {
  const branch = useSelector((state) => state.branch.branch);
  const { user } = useSelector((state) => state.user);
  const [alreadyTranslated, setAlreadyTranslated] = useState(false);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const dishes = useSelector((state) => state.dishes.dishes);
  const { id } = useParams();
  const [waiterDish, setWaiterDish] = useState({});
  const classes = useStyles();
  const [online, setOnline] = useState(true);
  const orderInitialState = {
    id: Number(id),
    image: "",
    name: "",
    quantity: 1,
    comment: "",
    totalAmount: 0,
    totalItemAmount: 0,
    discountPercent: 0,
    items: [],
  };
  const [product, setProduct] = useState();
  const [order, setOrder] = useState(orderInitialState);
  const { prefix } = branch;
  const setting = branch?.setting || product?.setting;

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(setRefresh(false));
    const dish = dishes.find((d) => d.id === Number(id));
    if (dish) {
      setProduct(dish);
    }
    dispatch(setLoading(false));
  }, [branch, id, dishes]);

  if (
    branch.id === null ||
    branch?.prefix === "P" ||
    branch?.prefix === undefined
  ) {
    return <NotFound />;
  }

  if (!product) {
    return <Grid />;
  }

  window.addEventListener("offline", (event) => {
    setOnline(true);
  });

  window.addEventListener("online", (event) => {
    setOnline(false);
  });

  const Diet = ({ waiterAllergens }) => {
    const {
      branch: { setting },
    } = useSelector((state) => state.branch);
    const customColor = {
      color: `${setting?.general_text_color || "black"} !important`,
    };
    return (
      <React.Fragment>
        <ListItem>
          <Grid container direction="column" spacing={2}>
            <Grid
              item
              className={classes.title}
              sx={{ ...customColor, fontFamily: setting?.general_font }}
            >
              {t("product.dietTypes")}
            </Grid>
            {product.allergens.map((a) => {
              const waiterAllergen = waiterAllergens?.find(
                (wa) => wa.allergen.id == a.id,
              );
              return (
                <Grid item key={a.id}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <img
                        src={a.image && online ? a.image : vegan}
                        className={classes.image}
                        onError={(e) => {
                          if (e.target.src !== vegan) {
                            e.target.src = vegan;
                          }
                        }}
                        alt={a.name}
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        sx={{
                          ...customColor,
                          fontFamily: setting?.dishes_description_font,
                        }}
                      >
                        {a.name}{" "}
                        {waiterAllergen?.id &&
                          `/ ${waiterAllergen.allergen.name}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </ListItem>
        <Divider />
      </React.Fragment>
    );
  };

  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  const fetchWaiterDish = () => {
    const token = user.accessToken;
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      ApiKey: process.env.REACT_APP_BACKOFFICE_API_KEY,
    };
    fetch(
      `${process.env.REACT_APP_REQUEST_BASE_URL}/dishes/${product.id}/waiter_dish?view=normal`,
      {
        method: "get",
        headers: new Headers(headers),
      },
    )
      .then((res) => res.json())
      .then((dish) => {
        setWaiterDish(dish);
        setAlreadyTranslated(true);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={12}>
        <Header branch={branch} product={product} />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          width: "99vw",
          overflow: "auto",
          height: product?.image ? "60vh" : "100vh",
          Bottom: "50px",
          marginTop: product?.image ? "-55px" : "0px",
          borderTopLeftRadius: product?.image ? "40px" : "0px",
          borderTopRightRadius: product?.image ? "40px" : "0px",
          backgroundSize: "cover",
          backgroundRepeat: "round",
          backgroundImage: `url(${branch?.setting?.background_general_image})`,
        }}
        className={classes.ul}
      >
        <List style={{ height: "80vh" }}>
          <Description
            product={product}
            branch={branch}
            waiterDish={waiterDish}
          />
          <Divider />
          {product?.allergens?.length > 0 && (
            <Diet waiterAllergens={waiterDish.dish_allergens} />
          )}
          <Parents
            parentsRelationship={product.accompaniments}
            waiterRelationship={waiterDish.dish_accompaniments}
            waiterParentKey={"accompaniment"}
            order={order}
            branch={branch}
          />
          <Parents
            parentsRelationship={product.toppings}
            waiterRelationship={waiterDish.dish_toppings}
            waiterParentKey={"topping"}
            order={order}
            setOrder={setOrder}
            branch={branch}
          />
          <Parents
            parentsRelationship={product.variants}
            waiterRelationship={waiterDish.dish_variants}
            waiterParentKey={"variant"}
            order={order}
            setOrder={setOrder}
            branch={branch}
          />
          {prefix === "T" && (
            <Comments text={order.comment} setText={setOrder} order={order} />
          )}
        </List>
        <Grid
          className={classes.button}
          style={{ display: alreadyTranslated ? "none" : "flex" }}
        >
          <Button
            variant="outlined"
            onClick={() => fetchWaiterDish()}
            style={{
              display:
                i18n.language !== branch?.primary_language ? "flex" : "none",
              bottom: "0",
              background:
                setting?.background_color_main_button ||
                "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%)",
              fontFamily: setting?.general_font,
              color: setting?.text_color_main_button || "white",
            }}
          >
            {t("product.waiterButton")}
          </Button>
        </Grid>
      </Grid>
      {prefix === "T" && (
        <Grid item>
          <Actions product={product} order={order} setOrder={setOrder} />
        </Grid>
      )}
    </Grid>
  );
};

export default Show;
