import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Chip, Divider, Grid, ListItem, Typography } from "@mui/material";
const useStyles = makeStyles(() => ({
  parentText: {
    fontFamily: "Rubik, sans-serif !important",
    fontStyle: "normal",
    color: "#666666",
    fontSize: 13,
    fontWeight: "bold",
  },
  chipAccompaniment: {
    height: "25px !important",
    margin: "5px !important",
    fontSize: "10px !important",
    color: "#E20613 !important",
    background: "white !important",
    boxShadow: "0px 4px 10px rgba(153, 153, 153, 0.15)",
    border: "1px solid transparent !important",
  },
}));

const ItemsCards = ({
  branch,
  parent,
  order,
  setOrder,
  waiterParent,
  waiterParentKey,
}) => {
  const classes = useStyles();
  const [maxLimit, setMaxLimit] = useState(false);
  const mode = window.localStorage.getItem("mode");
  const {
    branch: { setting },
  } = useSelector((state) => state.branch);
  const customColor = {
    color: `${setting?.general_text_color || "black"} !important`,
  };

  useEffect(() => {
    if (parent.limit) {
      const accItems = order.items.filter(
        (item) => item.item.parent === parent.name,
      );
      const currentTotalQuantity = accItems.reduce(
        (acc, item) => acc + item.quantity,
        0,
      );
      if (parent.limit === currentTotalQuantity) {
        setMaxLimit(true);
      } else {
        setMaxLimit(false);
      }
    }
  }, [parent, order]);

  const removeItem = (item, index) => {
    if (item && item.quantity > 0) {
      const newItems = [...order.items];
      if (item.quantity - 1 === 0) {
        newItems.splice(index, 1);
      } else {
        newItems[index] = { ...item, quantity: item.quantity - 1 };
      }
      setOrder({ ...order, items: newItems });
    }
  };

  const addItem = (acc, item, index) => {
    if (maxLimit) {
      return false;
    }

    const NOT_IN_ITEMS = -1;
    const newItems = [...order.items];
    if (index !== NOT_IN_ITEMS) {
      newItems[index] = { ...item, quantity: item.quantity + 1 };
      setOrder({ ...order, items: newItems });
    } else {
      newItems.push({
        id: acc.id,
        item: acc,
        quantity: 1,
      });
      setOrder({ ...order, items: newItems });
    }
  };

  return (
    parent.items &&
    parent.items
      .filter((i) => i.is_visible)
      .map((item) => {
        const newItem = { ...item, parent: parent.name };
        const itemOrder = order
          ? order.items.find((cItem) => cItem.id === item.id)
          : null;
        const index = order
          ? order.items.findIndex((cItem) => cItem.id === item.id)
          : null;
        const waiterItem = waiterParent
          ? waiterParent[0][waiterParentKey]?.items.find(
              (wp) => wp.id == item.id,
            )
          : {};
        return (
          <React.Fragment key={item.id}>
            <ListItem>
              <Grid
                container
                key={item.id}
                justifyContent="space-between"
                alignItems="center"
                className="item-container"
              >
                <Grid item xs={8.5}>
                  <Grid container direction="column">
                    <Grid item className={classes.parentText}>
                      <Typography
                        sx={{
                          ...customColor,
                          fontFamily: setting?.dishes_description_font,
                        }}
                      >
                        {item.name} {waiterItem.id && `/ ${waiterItem.name}`}
                      </Typography>
                    </Grid>
                    <Grid item className={classes.parentText}>
                      <Typography
                        sx={{
                          ...customColor,
                          fontFamily: setting?.dishes_description_font,
                        }}
                      >
                        {branch.currency?.symbol} {item.price}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  {mode === "T" && (
                    <Chip
                      icon={
                        <RemoveIcon
                          style={{ color: "#ff4b55" }}
                          onClick={() => removeItem(itemOrder, index)}
                        />
                      }
                      deleteIcon={
                        <AddIcon
                          style={{ color: maxLimit ? "white" : "#ff4b55" }}
                        />
                      }
                      onDelete={() => addItem(newItem, itemOrder, index)}
                      label={itemOrder ? itemOrder.quantity.toString() : "0"}
                      className={classes.chipAccompaniment}
                    />
                  )}
                </Grid>
                <Divider />
              </Grid>
            </ListItem>
            <Divider />
          </React.Fragment>
        );
      })
  );
};

export default ItemsCards;
