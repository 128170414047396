const HETranslation = {
  he: {
    translation: {
      popUp: {
        title: " עזרה בשימוש במציאות רבודה",
        text: "1.  כוונו את המצלמה אל שולחן או משטח בהיר.",
        text2: "2. הזיזו את הטלפון הסלולרי לאט במעגלים עד שציור היד נעלם",
        text3:
          "3. השתמשו באצבע אחת כדי להזיז את המנה ושתיים כדי לסובב אותה. אם תקרבו את המצלמה אל המנה, תראו אותה ביתר פירוט!",
      },
      buttons: {
        accept: "לְקַבֵּל",
      },
      sidebar: {
        languages: {
          title: "שפות",
          es: "ספרדית",
          en: "אנגלית",
          pt: " פורטוגזית",
          it: "אִיטַלְקִית",
          zh: "סִינִית",
          ar: "עֲרָבִית",
          fr: "צָרְפָתִית",
          he: "עִברִית",
          ru: "רוּסִי",
          ja: " יַפָּנִית",
          de: "גֶרמָנִיָת",
        },
      },
      index: {
        allCategories: "ראה הכל",
        searchPlaceholder: "?מה תרצה להזמין",
        filter: "לְסַנֵן",
        viewMenu3D: "ראה תפריט תלת מימד",
        moreInformation: "מידע נוסף",
        snackbar: "החלק אצבע משמאל לימין על מנה כדי לתרגם אותה לשפת המלצר.",
      },
      category: {
        product: {
          dish3d: "צלחת תלת מימד",
          outstanding: "יוצא מן הכלל",
          extras: "תוספות",
          dish360: "מנה ב-360",
        },
      },
      product: {
        see360: "ראה ב-360",
        see3D: "ראה בתלת מימד",
        ar: "מציאות רבודה (AR)",
        dietTypes: "סוגי דיאטה",
        waiterButton: "תרגם מנה למלצר",
      },
      product3D: {
        viewAR: "ראה במציאות רבודה (AR)",
        view3D: "ראה בתלת מימד",
      },
      filter: {
        title: "לְסַנֵן",
        dietTypes: "סוגי דיאטה",
        dishes3D: " מנות ב-3D / 360",
        clear: "לִמְחוֹק",
        apply: "להגיש מועמדות",
        featuredDishes: "מנות נבחרות",
        withDiscount: "עם הנחה",
      },
      error: {
        title: "!הו",
        subtitle: "משהו השתבש",
        caption:
          ".ודא שה-QR או הקישור שייכים למפעל המשויך ל-Cartear או נסה שוב",
      },
      about: {
        daysAndSchedules: "ימים ושעות",
        location: "מיקום",
        coverCharge: "עמלת כיסוי",
        socialNetworks: "רשתות חברתיות",
      },
      days: {
        0: "יום שני",
        1: "יום שלישי",
        2: "יום רביעי",
        3: "יום חמישי",
        4: "יום שישי",
        5: "שבת",
        6: "יום ראשון",
      },
    },
  },
};

export default HETranslation;
