import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import ArrowBack from "@mui/icons-material/ArrowBackIosNew";
import facebook from "../images/facebookAbout.svg";
import instagram from "../images/instagram.svg";
import whatsapp from "../images/whatsapp.svg";
import phone from "../images/phone.svg";
import { readLeftToRight } from "../services/languages.js";

const useStyles = makeStyles(() => ({
  arrowBack: {
    position: "absolute",
    top: 0,
    left: 0,
    marginTop: "10px",
    marginLeft: "10px",
  },
  branchImageContainer: {
    marginLeft: "-10px",
    marginTop: "10px",
  },
  branchImage: {
    width: "80px",
    borderRadius: "50px",
    border: "2px solid white",
    aspectRatio: "1/1",
  },
  titleContainer: {
    marginTop: "25px",
  },
  title: {
    fontWeight: "bold",
  },
  descriptionContainer: {
    marginTop: "5px",
    paddingLeft: "10px",
    color: "#666666",
  },
  description: {
    color: "#666666",
  },
  dayContainer: {
    color: "#666666",
    marginBottom: "5px",
  },
  images: {
    width: "25px",
    aspectRatio: "1/1",
  },
  mainContainer: {
    margin: "10px",
    overflow: "auto !important",
    paddingBottom: "20px",
  },
}));

const About = () => {
  const { branch } = useSelector((state) => state.branch);
  const { selectedDish } = useSelector((state) => state.dishes);
  const setting = branch?.setting || selectedDish?.setting;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const language = i18n.language;
  const classes = useStyles();
  const buildLink = (link) => {
    const re = new RegExp("^(http|https)://", "i");
    const match = re.test(link);
    if (match) {
      return link;
    } else {
      return `https://${link}`;
    }
  };
  const aboutObject = {
    image: branch.image || selectedDish.branch_image,
    name: branch.name || selectedDish.branch_name,
    description: branch.description || selectedDish.branch_description,
    schedules: branch.schedules || selectedDish.schedules,
    address: branch.address || selectedDish.branch_address,
    whatsapp: branch.whatsapp || selectedDish.whatsapp,
    facebook: branch.facebook || selectedDish.facebook,
    website: branch.website || selectedDish.website,
    instagram: branch.instagram || selectedDish.instagram,
    serviceCharge: branch.service_charge || selectedDish.service_charge,
    phone: branch.phone || selectedDish.branch_phone,
    currency: branch.currency || selectedDish.currency,
  };

  const newSchedules = {};

  aboutObject.schedules?.forEach((schedule) => {
    if (newSchedules[schedule.day]) {
      newSchedules[schedule.day] += `/${schedule.from}-${schedule.to}`;
    } else {
      newSchedules[schedule.day] = `${schedule.from}-${schedule.to}`;
    }
  });

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="flex-start"
      className={classes.mainContainer}
      sx={{ fontFamily: setting?.general_font }}
    >
      <Grid item xs={2} className={classes.arrowBack}>
        <ArrowBack
          onClick={() => {
            navigate(-1, { replace: true });
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        align="center"
        className={classes.branchImageContainer}
      >
        <img src={aboutObject.image} className={classes.branchImage} />
      </Grid>
      <Grid
        item
        xs={12}
        className={classes.titleContainer}
        dir={readLeftToRight(language)}
      >
        <Typography variant="h6" className={classes.title} sx={{ fontFamily: setting?.general_font }}>
          {aboutObject.name}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        className={classes.descriptionContainer}
        dir={readLeftToRight(language)}
      >
        <Typography variant="p" className={classes.description} sx={{ fontFamily: setting?.general_font }}>
          {aboutObject.description}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        className={classes.titleContainer}
        dir={readLeftToRight(language)}
      >
        <Typography variant="h6" className={classes.title} sx={{ fontFamily: setting?.general_font }}>
          {t("about.daysAndSchedules")}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        className={classes.descriptionContainer}
        dir={readLeftToRight(language)}
      >
        {Object.keys(newSchedules)?.map((day) => (
          <Grid
            container
            className={classes.dayContainer}
            alignItems="center"
            justifyContent="space-around"
            key={day}
          >
            <Grid item xs={6}>
              {t(`days.${day}`)}
            </Grid>
            <Grid item xs={6}>
              {newSchedules[day]}
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid
        item
        xs={12}
        className={classes.titleContainer}
        dir={readLeftToRight(language)}
      >
        <Typography variant="h6" className={classes.title} sx={{ fontFamily: setting?.general_font }}>
          {t("about.location")}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        className={classes.descriptionContainer}
        dir={readLeftToRight(language)}
      >
        {aboutObject.address}
      </Grid>
      <Grid
        item
        xs={12}
        className={classes.titleContainer}
        dir={readLeftToRight(language)}
      >
        <Typography variant="h6" className={classes.title} sx={{ fontFamily: setting?.general_font }}>
          {t("about.coverCharge")}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        className={classes.descriptionContainer}
        dir={readLeftToRight(language)}
      >
        {aboutObject.serviceCharge
          ? `${aboutObject?.currency?.symbol} ${aboutObject.serviceCharge}`
          : ""}
      </Grid>
      <Grid
        item
        xs={12}
        className={classes.titleContainer}
        dir={readLeftToRight(language)}
      >
        <Typography variant="h6" className={classes.title} sx={{ fontFamily: setting?.general_font }}>
          {t("about.socialNetworks")}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        className={classes.descriptionContainer}
        dir={readLeftToRight(language)}
      >
        <Grid container alignItems="center">
          <Grid item xs={1}>
            <img src={instagram} className={classes.images} />
          </Grid>
          <Grid item>
            <a href={buildLink(aboutObject.instagram)} target="_blank">
              {aboutObject.instagram?.split("instagram.com/")[1]}
            </a>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        className={classes.descriptionContainer}
        dir={readLeftToRight(language)}
      >
        <Grid container alignItems="center">
          <Grid item xs={1}>
            <img src={facebook} className={classes.images} />
          </Grid>
          <Grid item dir={readLeftToRight(language)}>
            <a href={buildLink(aboutObject.facebook)} target="_blank">
              {aboutObject.facebook?.split("facebook.com/")[1]}
            </a>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        className={classes.descriptionContainer}
        dir={readLeftToRight(language)}
      >
        <Grid container alignItems="center">
          <Grid item xs={1}>
            <img src={whatsapp} className={classes.images} />
          </Grid>
          <Grid item>
            <a
              href={buildLink(aboutObject.whatsapp)}
              target="_blank"
              rel="noopener"
            >
              {aboutObject.whatsapp}
            </a>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        className={classes.descriptionContainer}
        dir={readLeftToRight(language)}
      >
        <Grid container alignItems="center" justifyContent="flex-start">
          <Grid item xs={1}>
            <img
              src={phone}
              style={{ width: "35px", aspectRatio: "1/1", marginLeft: "-5px" }}
            />
          </Grid>
          <Grid item>
            <a href={`tel:${aboutObject.phone}`}>{aboutObject.phone}</a>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} align="center">
        <a href={buildLink(aboutObject.website)} target="_blank">
          {aboutObject.website}
        </a>
      </Grid>
    </Grid>
  );
};

export default About;
