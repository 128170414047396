const ZHTranslation = {
  zh: {
    translation: {
      popUp: {
        title: "幫助使用增強現實",
        text: "1. 將相機對準桌子或光線充足的表面。",
        text2: "2. 慢慢地移動手機，直到畫的手消失。",
        text3:
          "3. 用一根手指移動盤子，兩根手指旋轉盤子。 如果將相機靠近盤子，您會看到更多細節",
      },
      buttons: {
        accept: "接受",
      },
      sidebar: {
        languages: {
          title: "語言",
          es: "西班牙語",
          en: "英語",
          pt: "葡萄牙語",
          it: "意大利語",
          zh: "中國人",
          ar: "阿拉伯",
          fr: "法語",
          he: "希伯來語",
          ru: "俄語",
          ja: "日本人",
          de: "德語",
        },
      },
      index: {
        allCategories: "查看全部",
        searchPlaceholder: "你想點什麼？",
        filter: "篩選",
        viewMenu3D: "查看 3D 菜單",
        moreInformation: "更多資訊",
        snackbar: "在盤子上從左向右滑動手指，將其翻譯為服務員的語言。",
      },
      category: {
        product: {
          dish3d: "3D板",
          outstanding: "傑出的",
          extras: "附加選項",
          dish360: "360度菜餚",
        },
      },
      product: {
        see360: "全景觀看",
        see3D: "在 3D 中查看",
        ar: "增強現實 (AR)",
        dietTypes: "飲食類型",
        waiterButton: "翻譯菜餚給服務員",
      },
      product3D: {
        viewAR: "在增強現實 (AR) 中觀看",
        view3D: "在 3D 中查看",
      },
      filter: {
        title: "篩選",
        dietTypes: "飲食類型",
        dishes3D: "3D / 360 菜餚",
        clear: "擦除",
        apply: "申請",
        featuredDishes: "特色菜品",
        withDiscount: "有折扣",
      },
      error: {
        title: "哦!",
        subtitle: "有些不對勁",
        caption: "確保 QR 或鏈接屬於與 Cartear 關聯的機構或重試。",
      },
      about: {
        daysAndSchedules: "日期和時間",
        location: "位置",
        coverCharge: "餐具费",
        socialNetworks: "社交網絡",
      },
      days: {
        0: "星期一",
        1: "星期二",
        2: "星期三",
        3: "星期四",
        4: "星期五",
        5: "星期六",
        6: "星期日",
      },
    },
  },
};

export default ZHTranslation;
