import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { CircleFlag } from "react-circle-flags";
import {
  Alert,
  Button,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  Snackbar,
  Stack,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowForward from "@mui/icons-material/ArrowForwardIos";
import ArrowDown from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import filter from "../images/filter.svg";
import Categories from "../components/menu/categories/Categories";
import {
  setDisableWaiterModal,
  setShowSnackbar,
  setLastDishScrollPosition,
  setOpenSidebar,
  setViewFromIndex,
} from "../reducers/info";
import Filter from "./Filter";
import OrderButton from "../components/menu/OrderButton";
import Sidebar from "../components/utils/Sidebar";
import NotFound from "../pages/NotFound";
import { codeFlagsLanguage } from "../services/languages";

const useStyles = makeStyles(() => ({
  branchName: {
    fontSize: "16px !important",
    paddingTop: "5px",
    overflow: "hidden !important",
    width: "90vw",
    WebkitLineClamp: 1,
    LineClamp: 1,
    height: "25px",
    marginLeft: "15px",
  },
  branchSubtitle: {
    fontSize: "14px !important",
    paddingTop: "5px",
    overflow: "hidden !important",
    width: "90vw",
    WebkitLineClamp: 1,
    LineClamp: 1,
    height: "25px",
    marginLeft: "15px",
  },
  header: {
    background: "#FAFBFD",
    paddingTop: "20px",
    height: "90px",
    boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.1) !important",
    borderRadius: "0px 0px 10px 10px",
    width: "100%",
  },
  subHeader: {
    marginTop: "-65px !important",
  },
  search: {
    color: "#C0C0C0",
    height: "4vh",
    marginLeft: "3vw",
    borderRadius: "100px !important",
    width: "105%",
  },
  searchIcon: {
    color: "#C0C0C0",
  },
  hamburguer: {
    marginLeft: "-5vw",
  },
  filterText: {
    width: "auto",
    maxWidth: "100px",
  },
  textField: {
    paddingRight: "15px",
  },
  filterButton: {
    width: "40px",
  },
  filter: {
    color: "#C0C0C0 !important",
    background: "#FFFFFF",
    boxShadow: "0px 4px 10px rgba(153, 153, 153, 0.15) !important",
    borderRadius: "100px !important",
    textTransform: "capitalize !important",
    paddingRight: "15px",
    minWidth: "100px",
    maxWidth: "200px",
  },
  filterIcon: {
    maxWidth: "12px",
    display: "inline-flex",
  },
  restaurantLogo: {
    height: "70px",
    width: "70px",
    borderRadius: "50px",
    border: "2px solid white",
    boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.1) !important",
    marginLeft: "5vw",
  },
  chip: {
    background: "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%)",
    borderRadius: "60px !important",
    color: "white !important",
    fontSize: "10px !important",
    height: "18px !important",
  },
  menu3d: {
    background: "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%)",
    borderRadius: "60px !important",
    fontSize: "10px !important",
    height: "4vh !important",
    marginTop: "-10px !important",
    maxWidth: "200px",
    textTransform: "uppercase",
  },
  infoContainer: {
    marginBottom: "-10px !important",
  },
  info: {
    fontSize: "10px !important",
  },
  "@media (max-width: 915px) and (orientation:landscape)": {
    chip: {
      background: "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%)",
      borderRadius: "60px !important",
      color: "white !important",
      height: "18px !important",
    },
    menu3d: {
      background: "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%)",
      borderRadius: "60px !important",
      fontSize: "12px !important",
      height: "10vh !important",
      marginTop: "0% !important",
      maxWidth: "200px",
      textTransform: "uppercase",
    },
    infoContainer: {
      marginBottom: "-10px !important",
    },
    info: {
      color: "#C0C0C0 !important",
      fontSize: "10px !important",
      display: "none",
    },
    search: {
      color: "#C0C0C0",
      height: "8vh",
      marginLeft: "3vw",
      borderRadius: "100px !important",
      width: "120%",
    },
    restaurantLogo: {
      height: "80px",
      width: "80px",
      borderRadius: "46px",
      border: "5px solid white",
      boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.1) !important",
      marginLeft: "5vw",
    },
    filterButton: {
      width: "40px",
      paddingLeft: "28px",
    },
    filterIcon: {
      maxWidth: "12px",
      display: "inline-flex",
    },
    filter: {
      maxWidth: "12px",
      marginLeft: "15vw",
    },
    hamburguer: {
      marginLeft: "15vw",
      width: "20vh",
    },
    branchName: {
      fontSize: "3vw !important",
      paddingTop: "5px",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  "@media (min-width: 768px) and (max-width: 1366px) and (orientation: portrait)":
    {
      chip: {
        background: "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%)",
        borderRadius: "60px !important",
        color: "white !important",
        height: "18px !important",
      },
      menu3d: {
        background: "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%)",
        borderRadius: "60px !important",
        fontSize: "15px !important",
        height: "8vh !important",
        marginTop: "-15% !important",
        maxWidth: "200px",
        textTransform: "uppercase",
      },
      infoContainer: {
        marginBottom: "-10px !important",
      },
      info: {
        color: "#C0C0C0 !important",
        fontSize: "10px !important",
        display: "none",
      },
      search: {
        color: "#C0C0C0",
        height: "4vh",
        marginLeft: "3vw",
        borderRadius: "100px !important",
        width: "120%",
      },
      restaurantLogo: {
        height: "80px",
        width: "80px",
        borderRadius: "46px",
        border: "5px solid white",
        boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.1) !important",
        marginLeft: "5vw",
      },
      filterButton: {
        width: "40px",
        paddingLeft: "28px",
      },
      filterIcon: {
        maxWidth: "12px",
        display: "inline-flex",
      },
      filter: {
        maxWidth: "12px",
        marginLeft: "15vw",
      },
      hamburguer: {
        marginLeft: "15vw",
        width: "20vh",
      },
      branchName: {
        fontSize: "3vw !important",
        marginLeft: "-45%",
        paddingTop: "5px",
        left: 0,
      },
    },
  "@media (min-width: 768px) and (max-width: 1366px) and (orientation: landscape)":
    {
      menu: {
        fontSize: "30px",
      },
      menu3d: {
        fontSize: "15px !important",
        height: "35px !important",
      },
      search: {
        color: "#C0C0C0",
        height: "38px",
        marginLeft: "1vw",
        borderRadius: "100px !important",
        width: "130%",
      },
      filter: {
        maxWidth: "12px",
        marginLeft: "18vw",
      },
      hamburguer: {
        marginLeft: "15vw",
        width: "20vh",
      },
      branchName: {
        fontSize: "3vw !important",
        marginLeft: "-50%",
        paddingTop: "5px",
        left: 0,
      },
    },
  "@media (min-width: 768px) and (max-width: 1366px) and (orientation: portrait)":
    {
      menu: {
        fontSize: "30px",
      },
      chip: {
        background: "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%)",
        borderRadius: "60px !important",
        color: "white !important",
        height: "18px !important",
      },
      menu3d: {
        background: "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%)",
        borderRadius: "60px !important",
        fontSize: "15px !important",
        height: "30px !important",
        marginTop: "-15% !important",
        maxWidth: "200px",
        textTransform: "uppercase",
      },
      infoContainer: {
        marginBottom: "-10px !important",
      },
      info: {
        color: "#C0C0C0 !important",
        fontSize: "10px !important",
        display: "none",
      },
      search: {
        color: "#C0C0C0",
        height: "30px",
        marginLeft: "3vw",
        borderRadius: "100px !important",
        width: "120%",
      },
      restaurantLogo: {
        height: "80px",
        width: "80px",
        borderRadius: "46px",
        border: "5px solid white",
        boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.1) !important",
        marginLeft: "5vw",
      },
      filterButton: {
        width: "40px",
        paddingLeft: "28px",
      },
      filterIcon: {
        maxWidth: "12px",
        display: "inline-flex",
      },
      filter: {
        maxWidth: "12px",
        marginLeft: "15vw",
      },
      hamburguer: {
        marginLeft: "15vw",
        width: "20vh",
      },
      branchName: {
        fontSize: "25px !important",
        marginLeft: "-100px",
        paddingTop: "5px",
        left: 0,
      },
    },
  "@media (min-width: 1024px) and (orientation: landscape)": {
    branchName: {
      fontSize: "25px !important",
      marginLeft: "-25vh",
      paddingTop: "5px",
      left: 0,
    },
    search: {
      height: "40px",
      borderRadius: "100px !important",
      width: "135%",
    },
    filterButton: {
      width: "40px",
      paddingLeft: "28px",
    },
    filterIcon: {
      maxWidth: "12px",
      display: "inline-flex",
    },
    filter: {
      maxWidth: "12px",
      marginLeft: "21vw",
    },
    restaurantLogo: {
      height: "90px",
      width: "90px",
      borderRadius: "55px",
      border: "5px solid white",
      boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.1) !important",
      marginLeft: "5vw",
    },
    hamburguer: {
      marginLeft: "15vw",
      width: "20vh",
    },
    menu3d: {
      background: "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%)",
      borderRadius: "60px !important",
      fontSize: "15px !important",
      height: "35px !important",
      marginTop: "-15% !important",
      maxWidth: "200px",
      textTransform: "uppercase",
    },
  },
  "@media (min-width: 1200px) and (orientation: landscape)": {
    branchName: {
      fontSize: "25px !important",
      marginLeft: "-280px",
      paddingTop: "5px",
      left: 0,
    },
    restaurantLogo: {
      height: "100px",
      width: "100px",
      borderRadius: "55px",
      border: "5px solid white",
      boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.1) !important",
      marginLeft: "5vw",
    },
    menu: {
      fontSize: "30px",
    },
  },
  "@media (min-width: 1024px) and (orientation: portrait)": {
    menu3d: {
      background: "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%)",
      borderRadius: "60px !important",
      fontSize: "15px !important",
      height: "35px !important",
      marginTop: "-10% !important",
      maxWidth: "200px",
      textTransform: "uppercase",
    },
    branchName: {
      fontSize: "25px !important",
      marginLeft: "-200px",
      paddingTop: "5px",
      left: 0,
    },
    filterButton: {
      width: "40px",
      paddingLeft: "28px",
    },
    filterIcon: {
      maxWidth: "20px",
      display: "inline-flex",
    },
    filter: {
      maxWidth: "20px",
      marginLeft: "20vw",
    },
    search: {
      height: "40px",
      borderRadius: "100px !important",
      width: "130%",
    },
    menu: {
      fontSize: "30px",
    },
  },
}));
const Index = () => {
  const { filterOpen } = useSelector((state) => state.filter.filter);
  const { showSnackbar, order, lastDishScrollPosition } = useSelector(
    (state) => state.info,
  );
  const branch = useSelector((state) => state.branch.branch);
  const location = useLocation();
  const { setting } = branch;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const { t, i18n } = useTranslation();
  const { isLoading } = useSelector((state) => state.loading);
  const checked = order.open;
  const [canView3D, setCanView3D] = useState(false);
  const [firstDish3D, setFirstDish3D] = useState({});
  const currentAmount = branch?.orders
    ? branch.orders.reduce((acc, o) => {
        const amount = o.discountPercent
          ? o.totalAmount * ((100 - o.discountPercent) / 100)
          : o.totalAmount;
        return acc + amount;
      }, 0)
    : 0;

  useEffect(() => {
    const div = document.getElementById("dishContent");
    if (div) {
      div.scrollTo(0, lastDishScrollPosition);
    }
  }, []);

  useEffect(() => {
    let dish = {};
    if (branch?.categories?.length > 0) {
      branch.categories.forEach((c) => {
        c.dishes.forEach((d) => {
          if (dish.id === undefined && d.url_3d_web) {
            dish = d;
            setFirstDish3D(d);
            setCanView3D(true);
          }
        });
      });
    }
  }, [branch]);

  if (filterOpen) {
    return <Filter />;
  }

  const languages = codeFlagsLanguage(t);
  const countryCode = languages.find(
    (hash) => hash.code === i18n.language,
  ).flag;

  return (
    <Grid
      sx={{
        overflow: "hidden !important",
        height: "100%",
        backgroundSize: "cover",
        backgroundRepeat: "round",
        backgroundImage: `url(${branch?.setting?.background_general_image})`,
      }}
    >
      <Sidebar tableId={branch?.table_id} />
      <Grid container sx={{ overflow: checked ? "hidden !important" : "auto" }}>
        <Grid
          item
          className={classes.header}
          sx={{ zIndex: checked ? -1 : "auto" }}
        >
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={1}>
              <IconButton
                sx={{ marginLeft: "1vw", color: "gray" }}
                onClick={() => {
                  window.history.pushState(null, null, null);
                  dispatch(setOpenSidebar(true));
                }}
              >
                <CircleFlag countryCode={countryCode} height="30" />
              </IconButton>
            </Grid>
            <Grid
              item
              xs={7}
              className={classes.textField}
              sx={{ fontFamily: branch.setting?.general_font }}
            >
              <TextField
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder={t("index.searchPlaceholder")}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon className={classes.searchIcon} />
                    </InputAdornment>
                  ),
                  className: classes.search,
                  style: {
                    fontFamily: setting?.general_font,
                  },
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Button
                height="5vh"
                className={classes.filter}
                onClick={() => navigate("filters")}
              >
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  spacing={1}
                >
                  <Grid
                    item
                    className={classes.filterText}
                    sx={{ fontFamily: branch.setting?.general_font }}
                  >
                    {t("index.filter")}
                  </Grid>
                  <Grid item className={classes.filterIcon}>
                    <img src={filter} alt="filter" width="12px" height="12px" />
                  </Grid>
                </Grid>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          spacing={5}
          className={classes.subHeader}
        >
          <Grid item xs={4}>
            {branch?.image && (
              <img
                src={branch?.image}
                alt="tea"
                className={classes.restaurantLogo}
              />
            )}
          </Grid>
          <Grid
            item
            xs={4}
            sx={{ visibility: canView3D ? "visible" : "hidden" }}
            onClick={() => dispatch(setViewFromIndex(true))}
          >
            <Chip
              label={t("index.viewMenu3D")}
              className={classes.menu3d}
              sx={{
                color: `${
                  setting?.text_color_main_button || "white"
                } !important`,
                fontFamily: branch.setting?.general_font,
                background: `${
                  setting?.background_color_main_button ||
                  "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%)"
                } !important`,
              }}
              onClick={() => navigate(`${firstDish3D.id}/product_viewer`)}
            />
          </Grid>
          <Grid item xs={4} style={{ marginLeft: "-20px" }}>
            <Link
              to={`${location.pathname}/about`}
              style={{ textDecoration: "none" }}
            >
              <Button style={{ textTransform: "none", width: "30vw" }}>
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="center"
                  className={classes.infoContainer}
                >
                  <Grid item xs={9}>
                    <Typography
                      className={classes.info}
                      sx={{
                        color: `${
                          setting?.general_text_color || "white"
                        } !important`,
                        fontFamily: branch.setting?.general_font,
                      }}
                    >
                      {t("index.moreInformation")}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <ArrowForward className={classes.info} />
                  </Grid>
                </Grid>
              </Button>
            </Link>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="space-around"
          spacing={22}
          sx={{ zIndex: checked ? -1 : "auto" }}
        >
          <Grid item>
            <Grid container direction="column" justifyContent="flex-start">
              <Grid item>
                <Grid container sx={{ marginLeft: "10px" }}>
                  <Grid item xs={12}>
                    <Typography
                      style={{ fontWeight: "bold" }}
                      className={classes.branchName}
                      sx={{
                        color: branch?.setting?.general_text_color || "black",
                        fontFamily: branch.setting?.general_font,
                      }}
                    >
                      {branch?.name}
                    </Typography>
                  </Grid>
                  {branch?.subtitle && (
                    <Grid item xs={12}>
                      <Typography
                        className={classes.branchSubtitle}
                        sx={{
                          fontWeight: "bold",
                          color: branch?.setting?.general_text_color || "black",
                          paddingTop: "0px !important",
                          fontSize: "14px !important",
                          fontFamily: branch.setting?.general_font,
                        }}
                      >
                        {branch?.subtitle}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {branch?.prefix === "T" && (
                <Grid item>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={1}
                  >
                    <Grid item>
                      <Chip
                        label="Delivey"
                        className={classes.chip}
                        style={{ display: "none" }}
                      />
                    </Grid>
                    <Grid item>
                      <Chip
                        label="Take Away"
                        className={classes.chip}
                        style={{ display: "none" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          {branch?.prefix === "T" && (
            <Grid item>
              <Chip
                label="Menu diario"
                onDelete={() => console.log("hola")}
                deleteIcon={<ArrowDown style={{ color: "black" }} />}
                variant="outlined"
                style={{
                  border: "1px solid #E20613",
                }}
              />
            </Grid>
          )}
        </Grid>
        <Grid
          item
          style={{
            width: "100%",
            zIndex: checked ? -1 : "auto",
            height: "70vh",
            overflowY: "auto",
          }}
        >
          {isLoading && (
            <Stack spacing={1}>
              <Grid container justifyContent="space-around" alignItems="center">
                <Grid item xs={12} sx={{ marginBottom: "10px" }}>
                  <Grid container spacing={1}>
                    <Grid item xs={3}>
                      <Skeleton
                        variant="rectangular"
                        sx={{ fontSize: "1rem" }}
                        height={30}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Skeleton
                        variant="rectangular"
                        sx={{ fontSize: "1rem" }}
                        height={30}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Skeleton
                        variant="rectangular"
                        sx={{ fontSize: "1rem" }}
                        height={30}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Skeleton
                        variant="rectangular"
                        sx={{ fontSize: "1rem" }}
                        height={30}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: "10px" }}>
                  <Skeleton variant="rectangular" height={60} />
                </Grid>
                <Grid item xs={11} sx={{ marginBottom: "10px" }}>
                  <Skeleton variant="rounded" height={150} />
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: "10px" }}>
                  <Skeleton variant="rectangular" height={60} />
                </Grid>
                <Grid item xs={11} sx={{ marginBottom: "10px" }}>
                  <Skeleton variant="rounded" height={150} />
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: "10px" }}>
                  <Skeleton variant="rectangular" height={60} />
                </Grid>
                <Grid item xs={11} sx={{ marginBottom: "10px" }}>
                  <Skeleton variant="rounded" height={150} />
                </Grid>
              </Grid>
            </Stack>
          )}
          {!isLoading && (
            <Categories
              search={search.toLowerCase()}
              currentLanguage={i18n.language}
            />
          )}
        </Grid>
        {branch?.prefix === "T" && (
          <OrderButton checked={checked} currentAmount={currentAmount} />
        )}
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={showSnackbar && i18n.language !== branch.primary_language}
        key={"bottom center"}
      >
        <Alert
          onClose={() => dispatch(setShowSnackbar())}
          severity="info"
          variant="filled"
          sx={{
            width: "100%",
            fontFamily: setting?.general_font,
            background:
              setting?.background_color_main_button ||
              "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%)",
            color: setting?.text_color_main_button || "white",
          }}
        >
          {t("index.snackbar")}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default Index;
