import React from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DishCard from "./DishCard";

const useStyles = makeStyles(() => ({
  root: {
    height: "17vh",
    width: "max-content",
    whiteSpace: "nowrap",
    overflowY: "hidden",
    overflowX: "auto",
  },
  "@media (max-width: 915px) and (orientation:landscape)": {
    root: {
      height: "max-content",
      width: "100%",
      overflowX: "hidden",
      overflowY: "scroll",
      paddingBottom: "200px",
    },
  },
}));

const DishesCard = ({ dishes, currentDish, setSelectedDish }) => {
  const classes = useStyles();

  if (!dishes) {
    return <></>;
  }

  const dishesWith3d = dishes.filter(
    (dish) => dish.url_3d_web || dish.url_3d_android || dish.url_3d_ios,
  );

  return (
    <Grid container spacing={2} className={classes.root}>
      {dishesWith3d.map((dish) => (
        <DishCard
          dish={dish}
          key={dish.id}
          currentDish={currentDish}
          setSelectedDish={setSelectedDish}
        />
      ))}
    </Grid>
  );
};

export default DishesCard;
