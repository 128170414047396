import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CategoryCard from "./CategoryCard";
import {
  setLastDishScrollPosition,
  setSelectedCategoryPosition,
  setDisableWaiterModal,
} from "../../../reducers/info";

const useStyles = makeStyles(() => ({
  tabContainer: {
    position: "absolute",
    left: 0,
    height: "40px",
    width: "100%",
    zIndex: 100,
    marginTop: "-3px",
    whiteSpace: "nowrap",
    marginBottom: "20px",
  },
  tabPanel: {
    overflowX: "hidden",
    paddingTop: "30px",
    zIndex: "0 !important",
    position: "relative",
  },
}));

const Categories = ({ search, currentLanguage }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const branch = useSelector((state) => state.branch.branch);
  const { setting } = branch;
  const dishes = useSelector((state) => state.dishes.dishes);
  const filters = useSelector((state) => state.filter.filter.filters);
  const { selectedCategoryPosition  } = useSelector(
    (state) => state.info,
  );
  const [value, setValue] = useState(selectedCategoryPosition?.position || 0);
  const [categories, setCategories] = useState([
    { name: t("index.allCategories") },
    ...branch.categories,
  ]);
  const handleChange = (_event, newValue) => {
    dispatch(setLastDishScrollPosition(0));
    dispatch(
      setSelectedCategoryPosition({
        position: newValue,
        categoryId: categories[Number(newValue)]?.id,
      }),
    );
    setValue(newValue);
  };

  useEffect(() => {
    const div = document.getElementById("dishContent");
    if (div) {
      div.scrollTo(0, 0);
    }
  }, [value]);

  useEffect(() => {
    setCategories([{ name: t("index.allCategories") }, ...branch.categories]);
  }, [t, currentLanguage]);

  useEffect(() => {
    if (branch.categories.length !== 0) {
      setCategories([{ name: t("index.allCategories") }, ...branch.categories]);
      //dispatch(setLoading(false));
    }
  }, [branch, currentLanguage]);

  // filter categories by search
  useEffect(() => {
    let filteredCategories = branch.categories;
    if (filters) {
      filteredCategories = filteredCategories.map((category) => {
        let { dishes } = category;
        if (filters.diet?.length > 0) {
          dishes = dishes.filter((dish) =>
            dish.allergens.some((allergen) =>
              filters.diet.includes(allergen.id),
            ),
          );
        }
        if (filters.product3d) {
          dishes = dishes.filter((dish) => dish.url_3d_web);
        }
        if (filters.featuredDishes) {
          dishes = dishes.filter((dish) => dish.is_recommended);
        }
        if (filters.withDiscount) {
          dishes = dishes.filter((dish) => dish.discount_percent);
        }
        return {
          ...category,
          dishes: dishes,
        };
      });
      filteredCategories = filteredCategories.filter(
        (c) => c.dishes?.length > 0,
      );
      const newPosition = filteredCategories.findIndex(
        (c) => c.id === selectedCategoryPosition.categoryId,
      );
      setValue(newPosition === -1 ? "0" : String(newPosition + 1));
    }
    if (search) {
      const filteredDishes = dishes.filter((d) =>
        d.title.toLowerCase().includes(search),
      );
      const filteredIds = filteredDishes.map((d) => d.id);
      filteredCategories = filteredCategories
        .map((category) => {
          const newDishes = category.dishes.filter((d) =>
            filteredIds.includes(d.id),
          );
          if (newDishes.length > 0) {
            return { ...category, dishes: newDishes };
          }
        })
        .filter((c) => c !== undefined);
    }
    setCategories([{ name: t("index.allCategories") }, ...filteredCategories]);
  }, [search, filters, currentLanguage]);

  const AllCategoriesCard = () => {
    return categories.map((category, index) => {
      if (index !== 0) {
        return <CategoryCard key={category.id} category={category} />;
      } else {
        return <Grid key="none" />;
      }
    });
  };

  const CategoryList = ({ category, index }) => {
    if (index === 0) {
      return <AllCategoriesCard key={`allCategories-${category.id}`} />;
    } else {
      return (
        <CategoryCard
          category={category}
          key={`${category.id}${category.name}`}
        />
      );
    }
  };

  let isScrolling;

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", zIndex: 1000 }}>
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            variant="scrollable"
            sx={{
              backgroundColor: `${
                setting?.background_color_scroll_categories || "white"
              } !important`,
            }}
            className={classes.tabContainer}
            TabIndicatorProps={{
              style: {
                background: "#E20613",
                textTransform: "capitalize !important",
                overflowX: "auto",
                whiteSpace: "nowrap",
                display: "flex",
              },
            }}
          >
            {categories.map((category, index) => (
              <Tab
                label={category.name}
                value={String(index)}
                style={{
                  overflowX: "scroll",
                  textTransform: "capitalize",
                  fontFamily: setting?.categories_font,
                  fontStyle: "normal",
                  fontWeight: index === Number(value) ? "bold" : "normal",
                  color:
                    index === 0
                      ? setting?.general_text_color || "black"
                      : setting?.general_text_color || "#666666",
                }}
                key={`tab ${category.name}`}
              />
            ))}
          </TabList>
        </Box>
        <Box sx={{ zIndex: 1 }}>
          {categories.map((category, index) => (
            <TabPanel
              value={String(index)}
              key={`tabpanel ${category.id}`}
              index={String(index)}
              sx={{ padding: "0px !important" }}
            >
              <Box
                sx={{
                  zIndex: 10,
                  overflowX: "hidden",
                  height: "700px",
                  marginBottom: "50px",
                }}
                id="dishContent"
              >
                <Grid sx={{ marginTop: "10px" }}>
                  <CategoryList category={category} index={index} />
                </Grid>
              </Box>
            </TabPanel>
          ))}
        </Box>
      </TabContext>
    </Box>
  );
};

export default Categories;
