import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getModelViewerUrlV2 } from "../../services/firebase";
import { setCurrentMode } from "../../reducers/info";
import { CircleFlag } from "react-circle-flags";
import Actions from "./Actions";
import { setLoading } from "../../reducers/loading.js";
import { setOpenSidebar } from "../../reducers/info.js";
import Sidebar from "../../components/utils/Sidebar";
import Loader from "../../components/utils/Loader";
import { codeFlagsLanguage } from "../../services/languages";
import "./viewer.css";
import { isSpin } from "../../services/url.js";

const useStyles = makeStyles(() => ({
  sirv: {
    marginTop: "100px !important",
    zIndex: 10,
  },
  dish: {
    marginTop: "20vh",
    marginLeft: "20px",
    position: "absolute",
  },
  text: {
    paddingTop: "20px",
    fontSize: "1em",
    fontWeight: "bold",
    paddingRight: "20px",
  },
  actions: {
    paddingLeft: "-40px",
    width: "100%",
    position: "absolute",
  },
  price: {
    paddingTop: "5px",
    fontSize: "0.8em",
    fontWeight: "bold",
    paddingRight: "20px",
  },
  description: {
    paddingTop: "5px",
    fontSize: "0.8em",
    color: "#666666",
    fontWeight: "bold",
    paddingRight: "20px",
  },
  gridDishPlaceholder: {
    marginLeft: "0vw",
    marginTop: "-5vh",
  },
  dishPlaceholder: {
    position: "relative",
    marginTop: "-100px",
    width: "80vw",
    zIndex: 1,
  },
  propmtImg: {
    animation: "circle 4s linear infinite",
  },
  "@media (max-width: 915px) and (orientation:landscape)": {
    actions: {
      paddingLeft: "130px",
      width: "40%",
      position: "absolute",
      bottom: "27%",
    },
    text: {
      fontSize: "1em",
      fontWeight: "bold",
    },
    dish: {
      marginLeft: "68%",
      width: "32vw",
      top: -20,
    },
    title: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 2,
      LineClamp: 2,
      WebkitBoxOrient: "vertical",
      fontWeight: "bold",
      whiteSpace: "break-spaces !important",
      height: "30%",
    },
    description: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 2,
      LineClamp: 2,
      WebkitBoxOrient: "vertical",
      fontWeight: "bold",
      whiteSpace: "break-spaces !important",
      height: "30%",
    },
  },
  "@media (min-width: 768px) and (max-width: 1366px) and (orientation: portrait)":
    {
      text: {
        paddingTop: "0px",
        fontSize: "2em",
        fontWeight: "bold",
      },
      price: {
        paddingTop: "5px",
        fontSize: "1em",
        fontWeight: "bold",
      },
      description: {
        paddingTop: "5px",
        fontSize: "1em",
        color: "#666666",
        fontWeight: "bold",
      },
    },
  "@media (min-width: 915px) and (max-width: 1366px) and (orientation: landscape)":
    {
      text: {
        paddingTop: "0px",
        fontSize: "2em",
        fontWeight: "bold",
      },
      price: {
        paddingTop: "5px",
        fontSize: "1em",
        fontWeight: "bold",
      },
      description: {
        paddingTop: "5px",
        fontSize: "1em",
        color: "#666666",
        fontWeight: "bold",
      },
    },
}));

const Viewer = ({ dish, fullScreen, setFullScreen, setShow, show, tab }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [source, setSource] = useState(null);
  const [is360, setIs360] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [disableZoom, setDisableZoom] = useState(false);
  const { currentMode } = useSelector((state) => state.info);
  const branch = useSelector((state) => state.branch.branch);
  const setting = branch?.setting || dish?.setting;
  const { t, i18n } = useTranslation();
  const [scrollToDish, setScrollToDish] = useState(true);
  const languages = codeFlagsLanguage(t);
  const countryCode = languages.find(
    (hash) => hash.code === i18n.language,
  ).flag;

  const customColor = {
    color: `${setting?.general_text_color || "black"} !important`,
  };

  const chipColor = {
    color: `${setting?.text_color_main_button || "white"} !important`,
    background: `${
      setting?.background_color_main_button ||
      "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%)"
    } !important`,
  };

  async function getUrlAndSetSource(dishUrl, setSource) {
    getModelViewerUrlV2(dishUrl).then((url) => {
      if (isSpin(url)) {
        setIs360(true);
        setSource(`${url}?fullscreen=false`);
      } else {
        setIs360(false);
        setSource(url);
      }
    });
  }

  useEffect(() => {
    if (dish) {
      if (dish.url_3d_web?.includes("https")) {
        if (isSpin(dish.url_3d_web)) {
          setIs360(true);
          setSource(`${dish.url_3d_web}?fullscreen=false`);
        } else {
          setIs360(false);
          setSource(dish.url_3d_web);
        }
      }
      if (dish.url_3d_web && !dish.url_3d_web?.includes("https")) {
        getUrlAndSetSource(dish.url_3d_web, setSource);
      }
    }
  }, [dish]);

  useEffect(() => {
    if (!localStorage.getItem("popupShown") && currentMode === "AR") {
      setTrigger(true);
      localStorage.setItem("popupShown", "true");
    }
  }, [currentMode]);

  // Aquí puedes realizar las acciones que desees cuando se detecte el intento de salir de la página web, incluyendo el botón "Back" del navegador
  window.onbeforeunload = () => {
    dispatch(setCurrentMode("3D"));
  };

  const loadSirv = (components) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://scripts.sirv.com/sirvjs/v3/sirv.js";
      script.type = "text/javascript";
      script.async = true;
      if (components) {
        script.dataset.components = components;
      }
      script.onload = resolve;
      document.body.appendChild(script);
    });
  };

  const sirvLanguages = () => {
    const smvLocalization = {
      en: { spinHint: "Drag to spin" },
      fr: { spinHint: "Glisser pour pivoter" },
      es: { spinHint: "Arrastrar para girar" },
      pr: { spinHint: "Arraste para girar" },
      it: { spinHint: "Trascina per ruotare" },
      zh: { spinHint: "拖动旋转" },
      ar: { spinHint: "اسحب للتدوير" },
      he: { spinHint: "גרור כדי לסובב" },
      ru: { spinHint: "Перетащите для вращения" },
      ja: { spinHint: "ドラッグして回転" },
      de: { spinHint: "Zum Drehen ziehen" },
    };
    return smvLocalization[i18n.language].spinHint;
  };

  useEffect(() => {
    if (dish && scrollToDish) {
      const categoryDiv = document.getElementById(
        `tab_panel_categories_viewer_${tab}`,
      );
      const dishDiv = document.getElementById(`${dish.id}_viewer`);
      if (categoryDiv && dishDiv) {
        const { x } = dishDiv.getBoundingClientRect();
        categoryDiv.scrollTo(x, 0);
        setScrollToDish(false);
      }
    }

    loadSirv("spin, zoom, video").then(() => {
      window.Sirv.stop();
      if (is360) {
        window.Sirv.start();
        // setTimeout(() => {
        //   document.querySelector('.sirv-hint-message').innerText = sirvLanguages();
        // }, [10000])
      } else {
        window.Sirv.start();
      }
      dispatch(setLoading(false));
    });
  }, [source]);

  return (
    <Grid item sx={{ height: "100%", margin: "auto" }}>
      <Grid
        container
        alignItems="center"
        justifyContent="start-flex"
        sx={{ height: "100%", marginTop: "5vh" }}
      >
        <Grid item>
          {!branch?.id && (
            <Grid item xs={1}>
              <IconButton
                sx={{ marginLeft: "1vw", color: "gray" }}
                onClick={() => {
                  window.history.pushState(null, null, null);
                  dispatch(setOpenSidebar(true));
                }}
              >
                <CircleFlag countryCode={countryCode} height="30" />
              </IconButton>
            </Grid>
          )}
        </Grid>
        <Grid item>
          <Sidebar tableId={branch?.table_id} />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            height: fullScreen ? "100vh" : "50vh",
            marginTop: fullScreen ? "-45vh !important" : "0px",
          }}
        >
          {source && (
            <div
              className={`Sirv ${classes.sirv}`}
              data-src={source}
              data-options="ar.zoom: false; fullscreen.enable:false"
            />
          )}
        </Grid>
        <Grid
          item
          className={classes.actions}
          x={{
            bottom: 0,
            left: 0,
            right: 0,
            position: "absolute",
          }}
        >
          <Actions
            trigger={trigger}
            setTrigger={setTrigger}
            disableZoom={disableZoom}
            setDisableZoom={setDisableZoom}
            setFullScreen={setFullScreen}
            fullScreen={fullScreen}
            selectedDish={dish}
            show={show}
            setShow={setShow}
            isSpin={is360}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Viewer;
