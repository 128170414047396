import React, { forwardRef } from "react";
import { TabPanel } from "@mui/lab";
import DishesCard from "./DishesCard";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  root: {
    height: "15vh",
    overflowY: "hidden",
    overflowX: "auto",
    marginLeft: "-6vw",
    position: "relative",
    zIndex: "0",
  },
  "@media (max-width: 915px) and (orientation:landscape)": {
    root: {
      height: "100vh",
      overflowY: "auto",
      marginLeft: "-6vw",
      position: "relative",
      width: "20%",
    },
  },
}));

const TabBody = forwardRef((props, ref) => {
  const classes = useStyles();
  const { categories, currentDish, setSelectedDish } = props;
  const scrollStyles = {
    maxHeight: "300px", // Set a maximum height for the container
    overflowY: "scroll", // Enable vertical scrolling
    scrollbarWidth: "none", // Hide the scrollbar for Firefox
    "&::-webkit-scrollbar": {
      display: "none", // Hide the scrollbar for Chrome, Safari, and Opera
    },
  };

  if (!categories) {
    return <></>;
  }

  return (
    categories &&
    categories.map((category, index) => (
      <TabPanel
        value={String(index)}
        key={`tabpanel ${category.id}`}
        index={String(index)}
        ref={ref}
        className={classes.root}
        style={scrollStyles}
        id={`tab_panel_categories_viewer_${index}`}
      >
        <DishesCard
          currentDish={currentDish}
          dishes={category.dishes}
          setSelectedDish={setSelectedDish}
        />
      </TabPanel>
    ))
  );
});

export default TabBody;
