import React, { useState } from "react";
import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";

const style = {
  width: "60vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};

const WaiterModal = ({ dish, open, setOpen, setting, loadingWaiterDish }) => {
  window.onbeforeunload = () => {
    setOpen(false);
  };

  const customColor = {
    color: `${setting?.general_text_color || "black"} !important`,
  };

  const hideContent = {
    display: loadingWaiterDish ? "block" : "none",
  };
  const showContent = {
    display: !loadingWaiterDish ? "block" : "none",
  };

  return (
    <Modal
      open={open}
      onClose={(e, r) => {
        e.preventDefault();
        e.stopPropagation();
        if(r !== 'backdropClick') {
          setOpen(false);
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
      }}
    >
      <Box
        sx={{
          ...style,
          backgroundImage: `url(${setting?.background_general_image})`,
          backgroundSize: "cover",
          backgroundRepeat: "round",
        }}
      >
        <Grid container direction="column">
          <Grid item align="center" sx={{ ...hideContent }}>
            <CircularProgress />
          </Grid>
          <Grid
            item
            align="right"
            sx={{
              ...showContent,
              color: setting?.background_color_main_button,
              cursor: "pointer",
            }}
          >
            <CloseIcon
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setOpen(false);
              }}
            />
          </Grid>
          <Grid item>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ ...customColor, ...showContent }}
            >
              {dish?.title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, ...customColor, ...showContent }}
            >
              {dish?.description}
            </Typography>
          </Grid>
          <Grid item xs={12} align="center">
            <Button
              variant="outlined"
              sx={{
                color: setting?.text_color_main_button || "white",
                background: setting?.background_color_main_button,
                marginTop: '10px',
                ...showContent,
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setOpen(false);
              }}
            >
              OK
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default WaiterModal;
