export const BRANCH_URL = 1;
export const OBJECT_TYPE = 2;
export const DISH_ID = 3;
export const ALLOWED_OBJECT_TYPES = ["menu", "product"];

export const validateObjectType = (location) => {
  const splittedLocation = location.pathname.split("/");
  const urlObjectType = splittedLocation[OBJECT_TYPE].toLowerCase();
  const urlName = splittedLocation[BRANCH_URL].toLowerCase();
  const dishId = splittedLocation[DISH_ID]?.toLowerCase();
  if (ALLOWED_OBJECT_TYPES.includes(urlObjectType)) {
    return { urlName, urlObjectType, valid: true, dishId };
  }
  return { valid: false };
};

export const isSpin = (url) => {
  if (url === null) {
    return false;
  }
  const elements = url.split(".");
  return elements[elements.length - 1] === "spin";
};
