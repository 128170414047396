import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Chip, Divider, Grid, ListItem, Typography } from "@mui/material";
import ItemsCards from "./ItemsCards";

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: "bold",
  },
  subTitle: {
    color: "#666666",
    fontSize: 10,
  },
  chip: {
    height: "20px !important",
    borderRadius: "50px !important",
    margin: "5px !important",
    fontSize: "10px !important",
    color: "white !important",
    background:
      "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%) !important",
    border: "1px solid transparent !important",
  },
}));

const Parents = ({ branch, parentsRelationship, waiterRelationship, waiterParentKey, order, setOrder }) => {
  const classes = useStyles();
  const parents = parentsRelationship.filter(
    (parent) => parent.items.length > 0,
  );
  const { setting } = branch;
  const customColor = {
    color: `${setting?.general_text_color || "black"} !important`,
    fontFamily: setting?.general_font,
  };

  if (parents.length === 0) {
    return <></>;
  }

  return parents.map((parent, index) => (
    <React.Fragment key={`${parent.id}${index}`}>
      <ListItem>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography
                  className={classes.title}
                  sx={{
                    ...customColor,
                    fontFamily: setting?.dishes_description_font,
                  }}
                >
                  {parent.name}
                </Typography>
              </Grid>
              <Grid item>
                {branch?.prefix === "D" && parent.is_required && (
                  <Chip className={classes.chip} label="Obligatorio" />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.subTitle}>
            {branch?.prefix === "D" &&
              parent.limit > 0 &&
              `Selecciona máximo ${parent.limit} opciones`}
          </Grid>
        </Grid>
      </ListItem>
      <Divider />
      <ItemsCards
        parent={parent}
        order={order}
        setOrder={setOrder}
        branch={branch}
        waiterParent={waiterRelationship}
        waiterParentKey={waiterParentKey}
      />
    </React.Fragment>
  ));
};

export default Parents;
