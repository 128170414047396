import React from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ProductPlaceholder from "../../images/productPlaceholder.svg";
import { setLoading } from "../../reducers/loading.js";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
  image: {
    height: "10vh",
    width: "20vw",
    borderRadius: "10px",
  },
  scrollProductTitle: {
    maxWidth: "100% !important",
  },
  productTitle: {
    width: "20vw",
    fontSize: 8,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    LineClamp: 2,
    WebkitBoxOrient: "vertical",
    fontWeight: "bold",
    whiteSpace: "break-spaces !important",
    textAlign: "center",
  },
  "@media (max-width: 915px) and (orientation:landscape)": {
    margin: {
      width: "12vw",
      marginLeft: "45px",
      padding: "10px",
      height: "70px",
      marginBottom: "15vh",
    },
    image: {
      height: "8vw",
      width: "10vw",
      borderRadius: "10px",
    },
    productTitle: {
      width: "10vw",
      fontSize: 8,
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 2,
      LineClamp: 2,
      WebkitBoxOrient: "vertical",
      fontWeight: "bold",
      whiteSpace: "break-spaces !important",
      textAlign: "center",
      marginBottom: "10px",
    },
  },
  "@media (min-width: 768px) and (max-width: 1366px) and (orientation: landscape)":
    {
      image: {
        height: "90px",
        width: "100px",
        borderRadius: "10px",
      },
      productTitle: {
        width: "100px",
        fontSize: 10,
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        LineClamp: 2,
        WebkitBoxOrient: "vertical",
        fontWeight: "bold",
        whiteSpace: "break-spaces !important",
        textAlign: "center",
      },
      margin: {
        width: "14vw",
      },
    },
  "@media (min-width: 768px) and (max-width: 1366px) and (orientation: portrait)":
    {
      image: {
        height: "90px",
        width: "100px",
        borderRadius: "10px",
      },
      productTitle: {
        width: "10vw",
        fontSize: 10,
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        LineClamp: 2,
        WebkitBoxOrient: "vertical",
        fontWeight: "bold",
        whiteSpace: "break-spaces !important",
        textAlign: "center",
      },
      margin: {
        width: "15vw",
      },
    },
  "@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait)":
    {
      image: {
        height: "90px",
        width: "100px",
        borderRadius: "10px",
      },
      productTitle: {
        width: "90%",
        fontSize: 10,
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        LineClamp: 2,
        WebkitBoxOrient: "vertical",
        fontWeight: "bold",
        whiteSpace: "break-spaces !important",
        textAlign: "center",
      },
      margin: {
        width: "15vw",
      },
    },
  "@media (min-width: 768px) and (max-width: 1190px) and (orientation: landscape)":
    {
      image: {
        height: "90px",
        width: "100px",
        borderRadius: "10px",
      },
      productTitle: {
        width: "90%",
        fontSize: 10,
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        LineClamp: 2,
        WebkitBoxOrient: "vertical",
        fontWeight: "bold",
        whiteSpace: "break-spaces !important",
        textAlign: "center",
      },
      margin: {
        width: "15vw",
      },
    },
  "@media (min-width: 768px) and (max-width: 1366px) and (orientation: portrait)":
    {
      image: {
        height: "120px",
        width: "130px",
        borderRadius: "10px",
      },
      productTitle: {
        width: "100%",
        fontSize: 13,
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        LineClamp: 2,
        WebkitBoxOrient: "vertical",
        fontWeight: "bold",
        whiteSpace: "break-spaces !important",
        textAlign: "center",
      },
      margin: {
        width: "15vw",
      },
    },
  "@media (min-width: 1025px) and (max-width: 1366px) and (orientation: landscape)":
    {
      image: {
        height: "100px",
        width: "110px",
        borderRadius: "10px",
      },
      productTitle: {
        width: "100%",
        fontSize: 13,
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        LineClamp: 2,
        WebkitBoxOrient: "vertical",
        fontWeight: "bold",
        whiteSpace: "break-spaces !important",
        textAlign: "center",
      },
      margin: {
        width: "12vw",
      },
    },
}));

const DishCard = ({ dish, currentDish, setSelectedDish }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    branch: { setting },
  } = useSelector((state) => state.branch);
  return (
    <Grid
      item
      className={classes.margin}
      sx={{
        width: "25vw",
        marginLeft: "5vw",
        height: "100vh",
        padding: "30px",
        zIndex: "2",
      }}
      onClick={() => {
        if (currentDish?.id !== dish.id && currentDish?.id) {
          setSelectedDish(dish);
          dispatch(setLoading(true));
        }
      }}
      id={`${dish.id}_viewer`}
    >
      <Grid container direction="column" justifyContent="center">
        <Grid item xs={4}>
          <img
            src={dish.image ? dish.image : ProductPlaceholder}
            className={classes.image}
            onError={(e) => (e.target.src = ProductPlaceholder)}
          />
          <Typography
            variant="p"
            className={classes.productTitle}
            sx={{
              color: `${setting?.text_color_viewer || "black"} !important`,
              fontFamily: setting?.dishes_title_font,
            }}
          >
            {dish.title}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DishCard;
