import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Chip, Grid } from "@mui/material";
import ArrowBack from "@mui/icons-material/ArrowBackIosNew";
import BadgeOrders from "../utils/BadgeOrders";
import backgroundPlaceholder from "../../images/backgroundPlaceholder.svg";
import { isSpin } from "../../services/url";

const useStyles = makeStyles(() => ({
  header: {
    backgroundSize: "cover",
    backgroundRepeat: "round",
  },
  headerBackArrow: {
    fontSize: 20,
    marginTop: "30px",
  },
  image: {
    height: "5vh",
    width: "5vw",
  },
  containerChip: {
    marginBottom: "40px",
    position: "relative",
    zIndex: 1000000,
  },
  containerChip360: {
    marginTop: "0px",
    marginLeft: "90vw",
    position: "absolute",
    zIndex: 1000000,
  },
  chip360: {},
  chip3d: {
    height: "4.5vh !important",
    borderRadius: "50px !important",
    fontSize: "10px !important",
    border: "1px solid transparent !important",
    bottom: 0,
    left: 0,
    position: "relative",
  },
  "@media (max-width: 920px) and (orientation:landscape)": {
    chip3d: {
      height: "8vh !important",
      borderRadius: "50px !important",
      fontSize: "15px !important",
      border: "1px solid transparent !important",
      bottom: 0,
      left: 0,
      position: "relative",
    },
  },
  "@media (min-width: 768px) and (max-width: 1366px) and (orientation: portrait)":
  {
    chip3d: {
      height: "3vh !important",
      fontSize: "16px !important",
    },
  },
  "@media (min-width: 768px) and (max-width: 1366px) and (orientation: landscape)":
  {
    chip3d: {
      height: "8vh !important",
      fontSize: "16px !important",
    },
  },
}));

const Header = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { branch, product } = props;
  const { canActivateAR } = useSelector((state) => state.info);
  const { setting } = branch;
  const location = useLocation();
  const mode = window.localStorage.getItem("mode");
  const [background, setBackground] = useState(product.image);
  const canView3d = product.url_3d_web;
  const openAR = () => window.localStorage.setItem("openAR", true);
  const navigate = useNavigate();

  //    <Grid item className={classes.headerBadge}>
  //      { mode === 'T' && <BadgeOrders orders={branch.orders}/> }
  //    </Grid>
  //
  const chipColor = {
    color: `${setting?.text_color_main_button || "white"} !important`,
    background: `${setting?.background_color_main_button ||
      "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%)"
      } !important`,
    fontFamily: setting?.general_font,
  };

  const is_360 = isSpin(product?.url_3d_web);

  return (
    <Grid
      container
      className={classes.header}
      style={{
        backgroundImage: `url(${background})`,
        width: product?.image ? "100vw" : "0px",
        aspectRatio: "1 / 1",
      }}
      justifyContent="space-around"
    >
      <Grid
        item
        xs={11}
        className={classes.headerBack}
        sx={{
          marginTop: product?.image ? "30px" : "0px",
        }}
      >
        <ArrowBack
          fontSize="20"
          onClick={() => {
            navigate(-1, { replace: true });
          }}
          className={classes.headerBackArrow}
          sx={{
            color: setting?.background_color_main_button || "black",
            position: product?.image ? "static" : "absolute",
            zIndex: 10000,
          }}
        />
      </Grid>
      {product?.image && canView3d && (
        <Grid
          container
          className={classes.containerChip}
          alignItems="end"
          justifyContent="center"
          spacing={3}
        >
          <Grid item>
            <Chip
              label={is_360 ? t("product.see360") : t("product.see3D")}
              className={classes.chip3d}
              component={Link}
              sx={chipColor}
              to={`${location.pathname}/product_viewer`}
            />
          </Grid>
          {canActivateAR && !is_360 && (
            <Grid item>
              <Chip
                label={t("product.ar")}
                className={classes.chip3d}
                component={Link}
                sx={chipColor}
                to={`${location.pathname}/product_viewer`}
                onClick={() => openAR()}
              />
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default Header;
